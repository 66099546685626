module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favico.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"14e6fe711329543d8e05aca1acb263d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://wp.lifeguideconsultancy.com/graphql","html":{"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"protocol":"http","hostingWPCOM":false,"useACF":true,"schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
